<template>
  <div>
    <div v-if="mode === 'footer'">
      <div
        class="flex flex-col lg:flex-row items-end space-y-4 lg:space-y-0 lg:space-x-2 mt-6 w-full"
      >
        <input
          type="text"
          placeholder="Your Email"
          v-model="email"
          class="bg-transparent border-b-2 border-black text-black placeholder-black w-full"
        />
        <button
          class="lg:w-64 p-4 px-6 rounded-tl-2xl rounded-br-2xl bg-black text-white font-bold text-xs uppercase font-mmw-headline disabled:opacity-50"
          :disabled="!formValid"
          @click="submitForm"
        >
          Get Updates
        </button>
      </div>
      <div class="pt-2 flex items-start space-x-4 text-sm">
        <input type="checkbox" v-model="agreeToTerms" class="mt-2" />
        <span
          >I am over the age of 18 and have read the
          <a
            href="https://www.atlantisbahamas.com/privacy "
            class="border-b-2 border-white pb-1 text-mmw-blue"
            >Privacy Policy</a
          >. By submitting this form, I confirm I would like to receive emails
          on promotions, special offers and updates from Music Making Waves,
          Atlantis Paradise Island and its affiliated brands.</span
        >
      </div>
    </div>
    <div v-else>
      <div class="p-8 font-sans uppercase">
        <form>
          <div
            class="text-2xl max-w-xl leading-tight tracking-tight font-mmw-headline"
          >
            Experience some of the world's hottest artists direct from paradise.
          </div>
          <div class="mt-2 normal-case font-light font-sans2">
            Be the first to receive exclusive updates and offers.
          </div>
          <div>
            <div class="flex flex-col items-end space-y-2 mt-6 w-full">
              <input
                type="text"
                placeholder="Your Email"
                v-model="email"
                class="bg-gray-100 border-b-2 text-gray-600 placeholder-gray-400 flex-1 self-stretch px-4 py-3"
              />
            </div>

            <label
              class="pt-8 flex items-start space-x-3 text-sm normal-case font-light font-sans2 max-w-[400px]"
            >
              <input type="checkbox" v-model="agreeToTerms" />
              <span>
                I am over the age of 18 and have read the
                <a
                  href="https://www.atlantisbahamas.com/privacy "
                  class="border-b-2 border-white pb-1 text-mmw-blue"
                  >Privacy Policy</a
                >. By submitting this form, I confirm I would like to receive
                emails on promotions, special offers and updates from Music
                Making Waves, Atlantis Paradise Island and its affiliated
                brands.
              </span>
            </label>

            <button
              class="mt-8 p-4 px-6 rounded-tl-2xl rounded-br-2xl bg-mmw-teal text-black font-bold uppercase disabled:opacity-50"
              :disabled="!formValid"
              @click="submitForm"
            >
              Get Updates
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MmwFormSignup',

  props: {
    mode: {
      type: String,
      default: 'footer',
    },
  },

  data() {
    return {
      email: '',
      agreeToTerms: false,
    };
  },

  computed: {
    formValid() {
      return this.email && this.agreeToTerms;
    },
  },

  methods: {
    submitForm() {
      const data = {
        email: this.email,
        agree_to_terms: this.agreeToTerms,
        form: 'music-making-waves',
      };

      axios
        .post('/api/devise/form-signup', data)
        .then(() => {
          this.complete = true;
        })
        .catch((e) => {
          const { errors } = e.response.data;
          errors.forEach((error) => {
            window.deviseSettings.$bus.$emit('showError', error[0]);
          });
        });
    },
  },
};
</script>
