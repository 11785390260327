<script setup lang="ts">
const year = new Date().getFullYear()
</script>

<template>
  <div>
    <div class="mt-12 ">
      <svg viewBox="0 0 300 50" shape-rendering="geometricPrecision" class="pointer-events-none">
        <path
          transform="matrix(1.24699 0 0 0.957918-28.898684-115.058449)"
          d="M23.174752,120.113046c0,0,27.202072,17.619083,64.649081,17.619083s62.176166-17.619083,105.982101-17.619083c36.033914,0,52.284504,5.285725,69.948187,17.619083c0,21.475551,0,46.514379,0,65.895373-121.463286,0-181.935941,0-240.579369,0c0-29.24768,0-83.514456,0-83.514456Z"
          fill="#0DC6C8" stroke-width="0.6"
        />
      </svg>
    </div>

    <div class="lg:-mt-32 bg-mmw-teal text-black font-sans2">
      <div class="container max-w-[1200px] mx-auto">
        <div
          class="flex flex-col lg:flex-row justify-around lg:items-center lg:space-x-8 px-4 lg:px-0"
        >
          <div class="flex space-x-8 py-8 lg:py-0">
            <MmwMiniLogo class="text-white w-32" />
            <a href="https://atlantisblueproject.org" target="_blank" class="w-32">
              <img
                src="/imgs/logos/atlantis_blue_project_foundation_logo_black.png"
                alt="Atlantis Blue Project Foundation Logo"
              >
            </a>
          </div>
          <div class="lg:w-1/3 text-xl text-black">
            Be the first to know of upcoming shows and announcements
          </div>
          <div class="lg:w-1/3">
            <MmwFormSignup mode="footer" />
          </div>
        </div>
      </div>

      <mmw-mmw-divider class="text-mmw-blue mt-16" />

      <div
        class="big-container mx-auto text-xs text-center mt-8 flex flex-col lg:flex-row justify-around"
      >
        <ul v-cloak class="flex justify-center text-xl space-x-6">
          <li>
            <a class="text-glueblue-700" href="https://facebook.com/atlantisbahamas">
              <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" viewBox="0 0 24 24">
                <path
                  d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm3 8h-1.35c-.538 0-.65.221-.65.778v1.222h2l-.209 2h-1.791v7h-3v-7h-2v-2h2v-2.308c0-1.769.931-2.692 3.029-2.692h1.971v3z"
                />
              </svg>
            </a>
          </li>
          <li>
            <a class="text-glueblue-700" href="https://instagram.com/atlantisbahamas">
              <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" viewBox="0 0 24 24">
                <path
                  d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z"
                />
              </svg>
            </a>
          </li>
          <li>
            <a class="text-glueblue-700" href="https://www.pinterest.com/atlantisbahamas">
              <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" viewBox="0 0 24 24">
                <path
                  d="M12 0c-6.627 0-12 5.372-12 12 0 5.084 3.163 9.426 7.627 11.174-.105-.949-.2-2.405.042-3.441.218-.937 1.407-5.965 1.407-5.965s-.359-.719-.359-1.782c0-1.668.967-2.914 2.171-2.914 1.023 0 1.518.769 1.518 1.69 0 1.029-.655 2.568-.994 3.995-.283 1.194.599 2.169 1.777 2.169 2.133 0 3.772-2.249 3.772-5.495 0-2.873-2.064-4.882-5.012-4.882-3.414 0-5.418 2.561-5.418 5.207 0 1.031.397 2.138.893 2.738.098.119.112.224.083.345l-.333 1.36c-.053.22-.174.267-.402.161-1.499-.698-2.436-2.889-2.436-4.649 0-3.785 2.75-7.262 7.929-7.262 4.163 0 7.398 2.967 7.398 6.931 0 4.136-2.607 7.464-6.227 7.464-1.216 0-2.359-.631-2.75-1.378l-.748 2.853c-.271 1.043-1.002 2.35-1.492 3.146 1.124.347 2.317.535 3.554.535 6.627 0 12-5.373 12-12 0-6.628-5.373-12-12-12z"
                  fill-rule="evenodd" clip-rule="evenodd"
                />
              </svg>
            </a>
          </li>
          <li>
            <a class="text-glueblue-700" href="https://twitter.com/atlantisbahamas">
              <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" viewBox="0 0 24 24">
                <path
                  d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z"
                />
              </svg>
            </a>
          </li>
          <li>
            <a
              class="text-glueblue-700"
              href="https://www.youtube.com/atlantisbahamasofficial"
            >
              <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" viewBox="0 0 24 24">
                <path
                  d="M19.615 3.184c-3.604-.246-11.631-.245-15.23 0-3.897.266-4.356 2.62-4.385 8.816.029 6.185.484 8.549 4.385 8.816 3.6.245 11.626.246 15.23 0 3.897-.266 4.356-2.62 4.385-8.816-.029-6.185-.484-8.549-4.385-8.816zm-10.615 12.816v-8l8 3.993-8 4.007z"
                />
              </svg>
            </a>
          </li>
        </ul>

        <div class="mt-8 lg:mt-0">
          <span class="pr-1 mr-1 text-sm">
            <a href="https://www.atlantisbahamas.com/terms" class="text-black">
              Terms of Use
            </a>
          </span>
          <span class="pr-1 mr-1 text-sm">
            <a
              href="https://privacyportal.onetrust.com/webform/1a83e5ad-9ea4-49fa-91d0-295c0bddffd1/06533f77-836b-420f-bac4-74f4315462e8"
            >Exercise
              Your Privacy Rights</a>
          </span>
          <span class="pr-1 mr-1 text-sm">
            <a href="javascript:void(0)" class="ot-sdk-show-settings">
              Cookie Settings
            </a>
          </span>
          <span class="pr-1 mr-1 text-sm">
            <a
              href="https://privacyportal.onetrust.com/webform/1a83e5ad-9ea4-49fa-91d0-295c0bddffd1/06533f77-836b-420f-bac4-74f4315462e8"
            >Do
              Not Sell My Info</a>
          </span>
        </div>

        <div class="mt-4 lg:mt-0 pb-16 px-4 lg:px-16 text-sm text-center text-black opacity-75">
          &copy;{{ year }}
          Atlantis Bahamas - All rights reserved.
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>
