<script setup lang="ts">
import { useRoute } from 'vue-router'

const route = useRoute()
const nochrome = route.query.nochrome
</script>

<template>
  <div class="bg-mmw-blue w-full hidden lg:block ">
    <div v-if="!nochrome" class="big-container mx-auto flex flex-col lg:flex-row justify-between font-sans2">
      <a href="/music-making-waves" class="flex items-center">
        <MmwLogo class="w-28 lg:w-72 ml-8" />
      </a>
      <ul class="uppercase flex items-stretch justify-end space-x-6 text-white">
        <li>
          <a href="/music-making-waves/concerts" class="block text-white py-6">Concerts</a>
        </li>
        <li class="flex items-center">
          /
        </li>
        <li>
          <a href="/music-making-waves/concert-village" class="block text-white py-6">
            Concert Village
          </a>
        </li>
        <li class="flex items-center">
          /
        </li>
        <li>
          <a href="/music-making-waves/faqs" class="block text-white py-6">FAQ</a>
        </li>
        <li class="flex items-center">
          /
        </li>
        <li>
          <a href="/music-making-waves/make-waves" class="block text-white py-6">Make
            Waves</a>
        </li>
        <li class="flex items-center">
          /
        </li>
        <li>
          <a href="/" class="block text-white py-6">Explore
            Atlantis</a>
        </li>
        <li>
          <a
            href="https://secure.atlantisbahamas.com/travel/arc_waiting.cfm?nav=apiMobileDiscountedWeddingGuest&origin=http%253A%252F%252FAtlantisBahamas.com%252F&activity1=true&area2=%7Cairport%253ANAS&displayarea2=NAS&date1=04%2F27%2F2024&date2=04%2F27%2F2024&submit=Search"
            class="block px-6 py-6 text-mmw-blue bg-mmw-teal rounded-tl-[30px] "
          >
            Get
            Tickets
          </a>
        </li>
      </ul>
      <div class="lg:hidden mt-3">
        <a
          href="https://secure.atlantisbahamas.com/travel/arc_waiting.cfm?nav=apiMobileDiscountedWeddingGuest&origin=http%253A%252F%252FAtlantisBahamas.com%252F&activity1=true&area2=%7Cairport%253ANAS&displayarea2=NAS&date1=04%2F27%2F2024&date2=04%2F27%2F2024&submit=Search"
          class="py-0 px-3 pb-2 pt-2.5 leading-snug uppercase focus:outline-none font-sans text-2xs btn-ghost border-glueblue-600 text-glueblue-600 btn-glue-active"
        >
          Event
          Tickets
        </a>
      </div>
    </div>
  </div>
</template>
