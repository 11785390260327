<script>
export default {
  data() {
    return {
      showMenu: false,
    }
  },
}
</script>

<template>
  <div>
    <div
      class="absolute z-50 top-0 left-0 right-0 lg:hidden py-4 px-8 flex space-x-8"
    >
      <music-making-waves-logo class="w-full" />
      <button class="text-mmw-teal" @click="showMenu = true">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-10 h-10"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M3.75 9h16.5m-16.5 5.75h8.5"
          />
        </svg>
      </button>
    </div>

    <div v-if="showMenu" class="fixed z-50 inset-0 bg-mmw-blue">
      <div class="flex space-x-8 py-4 px-8">
        <music-making-waves-logo class="flex-1" />
        <button class="block text-white w-10" @click="showMenu = false">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="{1.5}"
            stroke="currentColor"
            className="w-10 h-10"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
      </div>

      <ul
        class="mt-8 h-screen uppercase text-white font-mmw-headline flex flex-col space-y-6"
      >
        <li class="px-8">
          <a href="/music-making-waves" class="block text-white">Music Making Waves Home</a>
        </li>
        <li class="px-8">
          <a href="/music-making-waves/concerts" class="block text-white">Concerts</a>
        </li>

        <li class="px-8">
          <a href="/music-making-waves/events" class="block text-white">Concert Village</a>
        </li>

        <li class="px-8">
          <a href="/music-making-waves/chefs" class="block text-white">FAQ</a>
        </li>

        <li class="px-8">
          <a href="/music-making-waves/faqs" class="block text-white">Make Waves</a>
        </li>

        <li class="px-8">
          <a href="/" class="block text-white">Explore Atlantis</a>
        </li>
        <li class="px-8">
          <a
            href="https://secure.atlantisbahamas.com/travel/arc_waiting.cfm?nav=apiMobileDiscountedWeddingGuest&origin=http%253A%252F%252FAtlantisBahamas.com%252F&activity1=true&area2=%7Cairport%253ANAS&displayarea2=NAS&date1=04%2F27%2F2024&date2=04%2F27%2F2024&submit=Search"
            class="block px-6 py-6 text-mmw-blue bg-mmw-teal rounded-tl-[30px] rounded-br-[30px]"
          >Get Tickets</a>
        </li>
      </ul>
    </div>
  </div>
</template>
